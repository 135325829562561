.sidebar {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #f6f6f9;
    overflow-x: hidden;
    overflow-y: hidden;
    transition: 0.5s;
    font-family: "Inter", sans-serif;
    // box-shadow: 0px 0px 24px rgba(27, 46, 94, 0.05);
    ::-webkit-scrollbar {
        -webkit-appearance: none;
        overflow:auto;
        }
        
        ::-webkit-scrollbar:vertical {
        width: 8px;
        }
        
        ::-webkit-scrollbar:horizontal {
        height: 12px;
        }
        
        ::-webkit-scrollbar-thumb {
        background-color: rgba(208, 208, 208, 0.5);
        border-radius: 10px;
        border: 2px solid #ffffff;
        }
        
        ::-webkit-scrollbar-track {
        border-radius: 10px; 
        background-color: #ffffff; 
        }

    // padding-top: 60px;
    ul {
        &.navList {
            background: transparent;
            margin-top: 14px;
            overflow-x: hidden;
            text-align: justify;
            height: calc(100vh - 160px);
            margin-left: 12px;
            margin-bottom: 10px;
        }
    }
    .nav-footer {
        margin-left: 12px;
        .footer-containar {
            display: flex;
            align-items: center;
            justify-content: space-between;
            img {
                max-width: 30px;
                margin-left: 14px;
            }
            .logout {
                padding: 5px 12px;
                border-radius: 0.375rem;
                background-color: #fff;
                a {
                    color: #4c4c4c;
                    text-decoration: none;
                    font-size: 0.85rem;
                    font-weight: 500;
                }
                svg {
                    font-size: 24px;
                    margin-bottom: 3px;
                    color: #4c5469;
                    cursor: pointer;
                    margin-left: 5px;
                }
                &:hover {
                    background-color: rgba(255, 210, 119, 0.146);
                    color: #eaa329;
                    cursor: pointer;
                    a {
                        color: #eaa329; 
                    }
                    svg {
                        color: #eaa329;
                    }
                }
            }
        }
    }
}

//   .sidebar a {
//     padding: 8px 8px 8px 32px;
//     text-decoration: none;
//     font-size: 25px;
//     color: #818181;
//     display: block;
//     transition: 0.3s;
//   }

.openbtn {
    font-size: 16px;
    width: 64px;
    height: 70px !important;
    background: #fff;
    color: #000;
    cursor: pointer;
    border: 0;

    // border-radius: 6px;
    &:hover {
        color: #fab33a;
        // background-color: rgba(0, 0, 0, 0.06);
    }
}


#main {
    transition: margin-left .5s;
}

.sidebar {
    .demo-logo-vertical {
        text-align: center;
        padding: 11px 0;
        // background-color: #fff;
        // border-right: 1px solid #e0e7ff;
        max-height: 69.68px;
        height: 100%;
        display: flex;
        align-items: center;
        margin-top: 5px;
        justify-content: center;
        .logo-expanded {
            display: block;
            margin: 0;

            img {
                max-width: 170px;
            }
        }

        .logo-collapsed {
            display: none;
        }
    }

    ul {
        margin-top: 18px;
        color: #ffffffa6;
        list-style: none;
        padding: 0;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

        li {
            // display: flex;
            align-items: center;
            transition: border-color 0.3s, background 0.3s, padding 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
            margin: 4px;
            
            border-radius: .375rem;
            padding: 9px 10px;
            margin-left: 0;
            margin-right: 0;
            // min-height: 50px;
            cursor: pointer;
            margin-bottom: 12px;
            position: relative;
            .submenu {
                margin: 0;
                background-color: #f8f9fa;
                padding: 5px;
                li {
                    display: flex;
                    min-height: 0;
                    a {
                        line-height: 35px;
                        margin-left: 23px;
                        width: 100%;
                        flex: auto;
                        min-width: 0;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        text-wrap: nowrap;
                        font-family: "Inter", sans-serif;
                        font-size: 0.8rem;
                        color: #1e293b;
                        font-weight: 500;
                        text-decoration: none;
                    }
                    &:hover {
                        background-color: transparent;
                        color: #252f4a;
                    }
                    &:first-child {
                        margin-top: 0.95rem;
                    }
                }
                
            }
            &.active {
                background-color: #fff;
                border-radius: .375rem;
                span {
                    color: #252f4a;
                }
                svg {
                    color: #252f4a;
                }
                
            }

            &:hover {
                background-color: rgba(255, 210, 119, 0.146);
                color: #eaa329;
                border-radius: 8px;
                span {
                    color: #eaa329;
                }
                svg {
                    color: #eaa329;
                }
            }

            span {
                line-height: normal;
                margin-left: 10px;
                width: 100%;
                flex: auto;
                min-width: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                text-wrap: nowrap;
                font-family: "Inter", sans-serif;
                font-size: 0.8rem;
                color: #1e293b;
                font-weight: 600;
                .down-arrow {
                    position: absolute;
                    margin-left: 0;
                    font-size: 10px;
                    right: -76px;
                    top: 15px;
                    .anticon {
                        width: 100%;
                        display: block;
                        svg {
                            font-size: 14px;
                            color: #1e293b;
                        }
                    }
                }
            }

            svg {
                display: inline-block;
                font-size: 30px;
                line-height: inherit;
                color: #000000;
                margin-bottom: 2px;
                background: #e7e7e7;
                padding: 4px;
                border-radius: 2px;
            }
            // &.ant-popover-open {
            //     display: none;
            // }
            .popover-icon {
                margin: 0;
            }
        }
    }

    &.collapsed {
        .logo-collapsed {
            display: block;
            margin: 0;

            img {
                max-width: 33px;
                margin-top: 6px;
                margin-right: 1px;
            }
        }

        .logo-expanded {
            display: none;
        }
        // ul {
        //     li {
        //         &.ant-popover-open {
        //             display: block;
        //         }
        //     }
        // }
    }
}

#main {
    .layout-header {
        height: 71px;
        position: sticky;
        display: flex;
        // border-bottom: 1px solid #f0f0f6;
        background-color: #f4f7fab3;
        top: 0;
        z-index: 10;
        width: 100%;
        justify-content: space-between;
        backdrop-filter: blur(7px);

        .header-item {
            .bg-body-tertiary {
                margin-right: 28px;
                background-color: #f3f3f9;
                height: 100%;
                min-width: 200px;

                .nav-item {
                    .dropdown-toggle {
                        padding: 0 20px;

                        .user-dropdown {
                            align-items: center;

                            img {
                                width: 45px;
                                height: 45px;
                                border-radius: 100%;
                                object-fit: cover;
                                outline: 3px solid #fff;
                            }

                            .user-name {
                                margin-left: 12px;
                                line-height: 1.5;

                                p {
                                    color: #495057;
                                    font-family: 600;
                                    font-size: 14px;
                                    margin: 0;
                                }

                                label {
                                    
                                    font-size: 14px;
                                    color: #405189;
                                    margin: 0;
                                    cursor: pointer;
                                    // font-weight: 600;
                                }
                            }
                        }

                        &::after {
                            display: none;
                        }
                    }
                    .dropdown-menu {
                        // width: 100%;
                        top: 57px;
                        .dropdown-item {
                            &.profile-item {
                                background: #ffffff;
                                margin-top: -8px;
                                border-radius: inherit;
                                padding-bottom: 20px;
                                padding-top: 10px;
                                border-bottom: 1px solid #d4d4d4;
                                    .profile-info {
                                        display: flex;
                                        flex-direction: column;
                                        label {
                                            font-size: 15px;
                                            margin-bottom: 11px;
                                            display: flex;
                                            align-items: center;
                                            gap: 5px;
                                            color: #4c5469;
                                            white-space: nowrap;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            display: block;
                                            &:last-child {
                                                margin-bottom: 0;
                                            }
                                        }
                                    }

                            }
                        }
                    }
                    
                }
            }

            .notification-dropdown {
                // display: none;

                .notificationBoardCard {
                    margin-bottom: 15px;
                    .unreadBadge {
                        // background-image: url(../../../assets/images/notification-badge.svg);
                    background-size: cover;
                    background-position: 50%;
                    background-repeat: no-repeat;
                    transform: rotate(-10deg);
                    position: absolute;
                    filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.35));
                    font-weight: bold;
                    color: #fff;
                    font-size: 12px;
                    text-transform: uppercase;
                    padding: 6px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    top: -16px;
                    left: -13px;
                    }
                    figure {
                        margin: 0;
                        img {
                            width: 100%;
                            max-height: 137px;
                            object-fit: cover;
                            border-radius: 0.25rem;
                        }
                    }
                    .title {
                        font-size: 1.1rem;
                        line-height: 1.5rem;
                        font-weight: 600;
                        margin: 10px 0;
                    }
                    .description {
                        font-size: 0.95rem;
                        line-height: 1.25rem;
                        color: #64748b;
                        margin: 10px 0;
                        // min-height: 20px;
                        // white-space: nowrap;
                        // overflow: hidden;
                        // text-overflow: ellipsis;
                    }
                    .postedDate {
                        font-family: "inter";
                        font-size: 0.8rem;
                        line-height: 1.25rem;
                        color: #64748b;
                    }
                  
                }   

                .dropdown-toggle {
                    .notification {
                        margin-right: 1rem;
                        position: relative;

                        a {
                            height: 42px;
                            width: 42px;
                            border-radius: 100%;
                            color: #495057;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            &:hover {
                                color: #3577f1;
                                background-color: #3577f11a;
                                border-color: #3577f11a;
                            }

                            svg {
                                font-size: 22px;
                            }
                        }

                        .badge {
                            position: absolute;
                            right: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 17px;
                            height: 17px;
                            background-color: #f06548 !important;
                        }
                    }

                    &::after {
                        display: none;
                    }
                }

                .dropdown-menu {
                    &.show {
                        display: block;
                        max-width: 320px;
                        width: 320px;
                        inset: 12px auto auto -277px !important;
                        transform: translate3d(0px, 45.6px, 0px) !important;
                        padding: 0;
                        padding-bottom: 10px;
                        border-radius: 0.25rem;
                        z-index: 200;
                        max-height: 300px;
                        border: 2px solid #f3f3f9;
                        overflow-y: scroll;
                        box-shadow: 0 5px 10px rgba(30, 32, 37, .12);
                        -webkit-animation-duration: .3s;
                        animation-duration: .3s;
                        -webkit-animation-fill-mode: both;
                        animation-fill-mode: both;

                        .dropdown-head {
                            background-color: #405189;
                            border-top-left-radius: 0.25rem;
                            border-top-right-radius: 0.25rem;

                            h6 {
                                margin: 0;
                                color: #fff;
                            }
                        }

                        .dropdown-item {
                            padding-top: 18px;
                        }
                    }
                }
                
            }
        }
    }

    .content {
        // background-color: #f4f7fab3;
        font-family: "Inter", sans-serif;
        // padding: 23px 12px 120px 12px;
        // padding: 1.5rem;
        height: calc(100vh - 30px);
        overflow-y: auto;
        scrollbar-width: auto;
        --tw-scrollbar-thumb-color: #000;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        align-items: stretch;
        border-radius: 12px;
        // background-color: #fff;
        // border: 3px solid #f1f1f1; 
        // padding-top: 20px;
        margin-top: 0;
        margin: 15px;
    }
}


