.login-form {
    input {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 10px 20px;
        font-size: 14px;
        outline: none;
        height: 55px;
        color: #535353;
        border-radius: 3px;
        border: 1px solid transparent;
        background: #f7f7f7;
    }
    .form-group {
        position: relative;
        .error-msg {
            color: #d32f2f;
            font-family: Inter, sans-serif;
            font-size: 13px;
            position: absolute;
        }
    }
}
.form-label {
    font-size: 0.875rem;
    font-weight: 500;
    white-space: nowrap;
    .required {
        font-size: 12px;
        color: #f00;
    }
}
.form-control, .form-select {
    font-size: 0.85rem;
    padding: 8px 12px;
}
.select-search {
    font-size: 0.85rem;
}
.input-group {
    .icon-eye {
        position: absolute;
        right: 12px;
        top: 12px;
        z-index: 10;
        cursor: pointer;
        svg {
            width: 20px;
            height: 20px;
        }
    }
    &.mobile-number-cc {
        .cc-select  {
            margin-right: 10px;
            font-size: 0.8rem;
            min-width: 100%;
        }
        
        .number-input {
            padding-left: 50px;
        }
        .mobile-number {
            position: relative;

            .cc-select {
                width: 33.33%;
                min-width: 10px;
            }
            .phone-number {
                position: relative;
                width: calc(66.66% - 28px);
            }
            .phone-input-field {
                width: fit-content;
                position: absolute;
                top: 3px;
                left: 0;
                border: 0;
                background: none;
                font-size: 0.85rem;
            }
        }
        .btn-remove {
            padding: 0;
            background-color: transparent;
            border: none;
            outline: none;
            position: absolute;
            right: 0;
            top: 6px;
        }
        .btn-add-phone-number {
            background-color: #fbb33a;
            color: #ffffff;
        }
    }
    &.reset-password {
        .cc-select {
            text-align: left;
            span + div {
                height: 58px;
                align-items: center;
            }
        }
        .number-input {
            height: 58px;
        }
        .phone-input-field {
            height: 100%;
            width: fit-content;
            position: absolute;
            top: 0px;
            border: 0;
            background: none;
            font-size: 0.85rem;
        }
        .btn-primary {
            position: absolute;
            right: 19px;
            top: 8px;
            padding: 11px 12px;
            border-radius: 0.375rem;
            background: linear-gradient(-180deg, #00D775, #00BD68);
            box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
        }
    }
}
p {
    &.error-msg {
        font-family: "Inter", sans-serif;
        color: #d32f2f;
        font-size: 13px;
        position: absolute;
        line-height: 16px;
    }
    &.mobile-num {
        top: 38px;
    }
}
.MuiAutocomplete-root {
    .MuiFormControl-root {
        .MuiInputBase-root {
            padding: 0px;
            input {
                padding: 9px 12px;
                font-size: 0.85rem;
            }
            .MuiSvgIcon-root {
                position: relative;
                top: 0;
                bottom: 0;
                left: 0;
            }
        }
    }
}

.toast {
    &.bg-danger {
        background: #fbeaec !important;
        color: #dc3545;
        border-color: #dc3545;
    }
    &.bg-success {
        background: #e8f3ee !important;
        color: #198754;
        border-color: #198754;
    }
    &.bg-warning {
        background: #fff9e6 !important;
        color: #ffc107;
        border-color: #ffc107;
    }
    .toast-header, .toast-body {
        background: transparent !important;
    }
    .toast-header {
        border-bottom: none;
        padding: 0;
        position: relative;
        .btn-close {
            position: absolute;
            right: 10px;
            top: 10px;
            color: #fff;
            font-size: 10px;
        }
    }
}