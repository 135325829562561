@import "fonts";
@import 'button';
@import 'form';
@import 'header';

body {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 400;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #f6f6f9;
}

.casa-phonebook-wrapper {
    .casa-phonebook-header {
        display: flex;
        padding-bottom: 1.25rem;
        justify-content: space-between;

        .title {
            display: flex;
            gap: 20px;
            align-items: baseline;
            margin-top: 10px;

            h4 {
                margin: 0;
                font-weight: 500;
                font-size: 1.125rem;
                color: #111827;
            }

            ol {
                &.breadcrumb {
                    margin: 0;

                    li {
                        color: #4b5675;
                        font-size: 0.8rem;

                        a {
                            color: #4b5675;
                            text-decoration: none;

                            &:hover {
                                text-decoration: underline;
                            }
                        }

                        &.active {
                            color: #111827;
                            font-weight: 500;
                        }
                    }
                }
            }
        }

        .search-col {
            position: relative;

            .search-input {
                // max-width: 200px;
                width: 100%;
                height: 38px;
                font-size: 0.8rem;
                background-color: #f6f6f9;
                padding-left: 25px;
            }

            svg {
                position: absolute;
                top: 13px;
                left: 6px;
                color: #888888;
            }
        }

        .position-relative {
            position: relative;
        }

        .list-group {
            z-index: 1000;
            max-height: 200px;
            overflow-y: auto;
        }

        .list-group-item {
            cursor: pointer;
        }
    }

    .casa-phonebook-body {
        .card {
            &.main-card {
                display: flex;
                flex-direction: column;
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
                background-color: #ffffff;
                /* Replace with your desired background color */
                border-radius: 0.75rem;
                border: 1px solid #e5e7eb;

                /* Replace with your desired border color */
                // margin-top: 10px;
                .card-header {
                    display: flex;
                    min-height: 56px;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 1px solid #e5e7eb;
                    /* Replace with your desired border color */
                    padding: 1.25rem 1.875rem;
                    padding-left: 1.25rem;
                    padding-right: 1.25rem;
                    background-color: #fff;
                    border-radius: 0.75rem 0.75rem 0 0;
                    border: 0;

                    .card-title {
                        margin: 0;

                        h3 {
                            font-size: 1rem;
                            line-height: 1.5rem;
                            font-weight: 600;
                            color: #1f2937;
                            margin: 0;
                            display: flex;
                            align-items: center;
                            gap: 5px;

                            .spinner {
                                font-size: 5px;
                            }
                        }
                    }
                }

                .card-body {
                    &.add-section {
                        border-top: 1px solid #e5e7eb;
                        min-height: calc(100vh - 157px);
                    }

                    .form-label {
                        &.label-view {
                            opacity: 0.7;
                        }
                    }

                    label {
                        &.view-data {
                            font-size: 0.875rem;
                            font-weight: 500;

                            img {
                                width: 35px;
                                height: 35px;
                                border-radius: 100%;

                            }
                        }
                    }

                    .forgot-password-container {
                        max-width: 710px;
                        margin: 0 auto;

                        h3 {
                            font-size: 1.5rem;
                            font-weight: 600;
                            margin: 18px 0;
                            color: #dc3545;
                        }

                        p {
                            font-size: 0.95rem;
                            font-weight: 300;
                            margin-bottom: 25px;
                            color: #000000;
                        }

                        .form-floating {
                            position: relative;

                            .btn-primary {
                                position: absolute;
                                right: 9px;
                                top: 8px;
                                padding: 11px 12px;
                                border-radius: 0.375rem;
                                background: linear-gradient(-180deg, #00D775, #00BD68);
                                box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
                            }
                        }

                        .verify-otp-btn {
                            background: linear-gradient(-180deg, #ffb201, #fbb33a);
                            border-radius: 5px;
                            box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
                            color: #FFFFFF;
                            cursor: pointer;
                            display: inline-block;
                            height: 44px;
                            line-height: 44px;
                            padding: 0 50px;
                            border: 0;
                            margin: 15px 0;

                            &:hover {
                                // background: #00bd68;
                            }
                        }

                        .password-container {
                            p {
                                color: #198754;
                                font-weight: 500;
                                margin: 10px 0 15px 0;
                            }
                        }

                        .error-msg {
                            font-size: 0.9rem !important;
                            margin-top: 2px !important;
                            font-weight: 400 !important;
                            color: #d32f2f !important;
                        }
                    }
                }
            }
        }

        // .table-responsive {
        //     table {
        //         thead {
        //             tr {
        //                 th {
        //                     padding: .625rem 1rem;
        //                     background-color: #fcfcfc;
        //                     color: #78829d;
        //                     font-weight: 500;
        //                     font-size: .8125rem;
        //                     line-height: 1.125rem;
        //                     vertical-align: middle;
        //                 }
        //             }
        //         }
        //         tbody {
        //             tr {
        //                 td {
        //                     padding: .75rem 1rem;
        //                     font-weight: 400;
        //                     color: #111827;
        //                     font-size: .875rem;
        //                 }
        //             }
        //         }
        //     }
        // }

        .table-responsive {
            // border-radius: 0.375rem;
            overflow-x: auto;
            overflow-y: auto;
            height: calc(100vh - 175px);
            border-bottom: 2px solid #f1f5f9;


            // box-shadow: 0px 5px 12px -12px rgba(0, 0, 0, 0.29);
            .table {
                // box-shadow: 0px 5px 12px -12px rgba(0, 0, 0, 0.29);
                min-width: 1000px !important;
                width: 100%;
                background: #fff;
                margin-bottom: 0;

                &.nowrap {
                    text-wrap: nowrap;
                }

                thead {
                    position: sticky;
                    top: -0.1px;
                    z-index: auto;

                    tr {
                        th {
                            font-weight: 500;
                            font-size: 0.85rem;
                            color: #ffffff;
                            padding: 13px 20px;
                            border-bottom: 0;
                            background-color: #6c757d;
                        }
                    }
                }

                tbody {
                    tr {

                        td,
                        th {
                            padding: 11px 20px;
                            // vertical-align: middle;
                            line-height: 1.462;
                            font-size: .85rem;
                            font-weight: 500;
                            color: #333335;
                            vertical-align: middle;
                            border-bottom: 2px solid #f1f5f9;

                            .action-col {
                                a {
                                    background-color: #e9f0f7;
                                    color: #4d556a;
                                    height: 35px;
                                    width: 35px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    border-radius: 0.375rem;
                                    font-size: 11px;
                                    transition-duration: .2s;
                                    cursor: pointer;

                                    svg {
                                        font-size: 15px;
                                    }

                                    &.edit:hover {
                                        color: #2d77ed;
                                        background-color: #cfe3fc;
                                    }

                                    &.delete:hover {
                                        color: #ef4444;
                                        background-color: #fee2e2;
                                    }

                                    &.view:hover {
                                        color: #2db33d;
                                        background-color: #d0fccf;
                                    }
                                }
                            }

                            .assign-btn {
                                padding: 6px 8px;
                                background-color: #eff6ff;
                                text-decoration: none;
                                border-radius: 6px;
                                color: #3b82f6;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                gap: 6px;
                                cursor: pointer;
                                font-size: 0.8rem;
                            }

                            .status-col {
                                span {
                                    padding: 6px 14px;
                                    color: #202020;
                                    font-size: 13px;
                                    border-radius: 30px;

                                    &.completed {
                                        border: 1px solid #6eff4c;
                                        background: #d2ffd4;
                                    }

                                    &.submitted {
                                        background: #e0edfe;
                                        border: 1px solid #3ba1f9;
                                    }

                                    &.inprogress {
                                        border: 1px solid #fbb33a;
                                        background: #fff9f0;
                                    }
                                }
                            }
                        }

                        .remarks {
                            a {
                                padding: 6px 8px;
                                background-color: #198754;
                                text-decoration: none;
                                border-radius: 6px;
                                color: #ffffff;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                gap: 6px;
                                cursor: pointer;
                                font-size: 0.8rem;
                            }
                        }

                        th {
                            font-weight: 600;
                            font-size: 0.9rem;
                        }

                        .checkbox {
                            input {
                                width: 45px;
                            }

                            .form-check-input:checked {
                                background-color: #00d220;
                                border-color: #00d220;
                            }
                        }

                        .show-contact-btn {
                            background-color: initial;
                            background-image: linear-gradient(-180deg, #00D775, #00BD68);
                            border-radius: 5px;
                            box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
                            color: #FFFFFF;
                            cursor: pointer;
                            display: inline-block;
                            padding: 5px 15px;
                            text-align: center;
                            font-size: 0.75rem;
                        }
                    }
                }
            }

            &.role-table {
                border-radius: 0.375rem;
                overflow-x: auto;
                overflow-y: auto;
                max-height: calc(100vh - 50vh);
                min-height: calc(100vh - 50vh);
                height: 100%;
            }

            &.export-table {
                max-height: calc(100vh - 37vh);
                min-height: calc(100vh - 37vh);
            }
        }

        .MuiPagination-ul {
            margin: 15px 0px;
            justify-content: flex-end;
            margin-right: 24px;

            .page-item {
                .page-link {
                    padding: 5px 14px;
                    border-radius: 4px;
                    font-size: 0.8rem;
                    color: #6c757d;
                }

                &.active {
                    .page-link {
                        background-color: #6c757d;
                        border-color: #6c757d;
                        color: #fff;
                    }
                }
            }
        }
    }

    .btn {
        font-family: "Inter", sans-serif;
        font-size: 0.85rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        padding: 7px 23px;
        border: 0;
        min-width: 110px;

        &.add-btn {
            background-color: #fbb339;
            padding: 5px 25px;
            border: 0;
            min-height: 38px;

            &>span {
                display: flex;
                align-items: center;
                gap: 3px;
            }
        }

        &.close-btn {
            color: #4c5468;
            border-color: #4d556a;
            background-color: transparent;
            border-width: 1px;
            border-style: solid;

            &:hover {
                background-color: #fff2f0;
            }

            span {
                display: flex;
                align-items: center;
                gap: 5px;
            }
        }

        &.submit-btn {
            background-color: #fcbb55;
            border: 0;
            color: #fff;
            transition-duration: .15s;

            &:hover {
                background-color: #f1ab39;
                color: #fff;
            }

            span {
                display: flex;
                align-items: center;
                gap: 5px;
            }
        }
    }
}

.widget-rounded-circle {
    box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, 0.03);
    border: 0;

    .card-body {
        padding: 1.5rem;
        background-color: #f4f4f4;
        border-radius: 8px;
        border: 2px solid #e9e9e9;

        .avatar-lg {
            height: 4.5rem;
            width: 4.5rem;
            display: flex;
            align-items: center;
            justify-content: center;

            &.bg-soft-primary {
                background-color: #6658dd40 !important;

                .dashboard-icon {
                    color: #6658dd;
                }
            }

            &.bg-soft-success {
                background-color: #1abc9c40 !important;
                border-color: #1abc9c !important;

                .dashboard-icon {
                    color: #1abc9c;
                }
            }

            &.bg-soft-warning {
                border-color: #f7b84b !important;
                background-color: #f7b84b40 !important;

                .dashboard-icon {
                    color: #f7b84b;
                }
            }

            &.bg-info-soft {
                background-color: #7eff523d;
                border-color: #00d220 !important;

                .dashboard-icon {
                    color: #00d220;
                }
            }

            &.bg-warning-soft {
                background-color: #ff52523d;
                border-color: #ff0000 !important;

                .dashboard-icon {
                    color: #ff0000;
                }
            }

            &.bg-blue-soft {
                background-color: rgb(150 215 255);
                border-color: #058eda !important;

                .dashboard-icon {
                    color: #1284cc;
                }
            }

            .anticon {
                font-size: 22px;
                color: #0d6efd;
            }

            svg {
                font-size: 1.5rem;
            }
        }
    }

}

.tooltip {
    &.phone-tooltip {
        opacity: 1;

        .tooltip-inner {
            border-radius: 5px;
            background: #FFFFFF;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            box-shadow: 0px 5px 20px rgb(0 0 0 / 17%);
            animation: ballong cubic-bezier(0.68, -0.55, 0.27, 1.55) 2000ms forwards;
            color: #000;
            border: 1px solid #e6e6e6;
            padding: 10px;
            font-size: 0.85rem;
            font-weight: 400;
            font-family: "Inter", sans-serif;
            flex-direction: column;
            gap: 3px;
        }

        .tooltip-arrow {
            &::before {
                border-top-color: #ffffff;
            }
        }
    }
}

.change-pswd-btn {
    button {
        background-color: #5f9ea0;

        &:hover {
            background-color: #7dafb1;
        }

        &:active {
            background-color: #7dafb1 !important;
        }

        &:focus-visible {
            background-color: #7dafb1;
        }
    }
}

.mobile-error-msg {
    top: -17px;
}